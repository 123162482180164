import React, { useEffect } from "react";
import wl1 from './Images/wl1.jpg'
import wl9 from './Images/wl9.jpg'
import wl3 from './Images/wl3.jpg'
import wl4 from './Images/wl4.jpg'
import wl5 from './Images/wl5.jpg'
import wl6 from './Images/wl6.jpg'
import wl7 from './Images/wl7.jpg'
import wl8 from './Images/wl8.jpg'
import wl11 from './Images/wl11.jpg'
import wl12 from './Images/wl12.jpg'
import wl13 from './Images/wl13.jpg'
import wl14 from './Images/wl14.jpg'
import wl15 from './Images/wl15.jpg'
import wl16 from './Images/wl16.jpg'
import wl17 from './Images/wl17.jpg'

import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'

export default function Gallery() {

  useEffect(()=>{
    AOS.init({
      offset : 100,
      duration : 800,
    });
    window.scrollTo(0,0);
  },[])

  return (
    <>
      <div className="h-[380px] bg-[#2b982b] flex justify-center items-center">
        <div className="lg:w-[500px] w-full p-5">
          <h3 className="text-white lg:text-[38px] text-[35px] leading-[50px] font-semibold text-center" data-aos="fade-up">
            Some Results by Our Customers
          </h3>
          <p className="text-white lg:text-sm text-[13px] text-center mt-4"  data-aos="fade-up">
          Discover what our customers have to say about their experiences with our natural health herbal solutions. Many have shared their success stories, citing reduced discomfort, increased energy levels, and a general sense of well-being.
          </p>
          <div className="text-center mt-5" data-aos="fade-up">
            <Link className="bg-white px-16 rounded-full py-2 text-[#2b982b]" to='/contact-us'>
              Message Us
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <div className="flex flex-col items-center"  data-aos="fade-up">
          <h2 className="lg:text-3xl text-2xl font-semibold">Let's See Transformation</h2>
          <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div>
        </div>
        <div className="flex justify-around flex-wrap w-full mt-20">
            <img src={wl7} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl5} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl9} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl1} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl3} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl4} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl6} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl8} alt="" className="m-5 w-[350px] h-[300px]" data-aos="fade-up"/>
            <img src={wl11} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl12} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl13} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl14} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl15} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl16} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
            <img src={wl17} alt="" className="m-5 w-[300px] h-[300px]" data-aos="fade-up"/>
        </div>
        <div className="flex justify-center mt-10">
            <div className="lg:text-center text-start lg:w-[600px] w-full px-8">
            <h2 className="text-[green] lg:text-4xl text-3xl font-semibold"  data-aos="fade-up">Are You Ready To Loss Your Weight ?</h2>
            <p className="text-slate-600 mt-5 lg:text-[16px] text-[15px]"  data-aos="fade-up">It's not just about losing weight; it's about gaining health, confidence, and a renewed sense of well-being. Together, let's set achievable goals, make mindful choices, and celebrate each milestone.Take the first step toward a lighter, brighter you and embrace a healthier, more vibrant life. </p>
            <div className="mt-4"  data-aos="fade-up">
            <Link to='/contact-us'>Contact Us
                <i className="fa fa-long-arrow-right mx-3 relative top-[2px]"></i>
            </Link>
            </div>
            </div>
        </div>
      </div>
    </>
  );
}
