import './App.css';
import Navbar from './component/Navbar';
import Homepage from './Pages/HomePage/Homepage';
import Footer from './component/Footer';
import ContactUs from './Pages/ContactPage/ContactUs';
import Gallery from './Pages/GalleryPage/Gallery';
import Services from './Pages/ServicePage/Sevices'
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="">
      <Navbar />
      <Routes>
        <Route path='/' element={<Homepage />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
        <Route path='/contact-us' element={<ContactUs />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
