import React, { useEffect } from "react";
import energy from "./Images/renewable-energy.png";
import brain from "./Images/brainstorm.png";
import health from "./Images/healthy.png";
import chart from "./Images/bar-chart.png";
import leaf from "./Images/leaf.png";
import running from "./Images/running.png";
import heart from "./Images/heart.png";
import doctor from "./Images/doctor.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Sevices() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
    });
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <div className="h-[380px] bg-[#2b982b] flex justify-center items-center">
        <div className="lg:w-[500px] w-full p-5">
          <h3
            className="text-white lg:text-[38px] text-[35px] leading-[50px] font-semibold text-center"
            data-aos="fade-up"
          >
            What We Provide ?
          </h3>
          <p
            className="text-white lg:text-sm text-[13px] text-center mt-4"
            data-aos="fade-up"
          >
            Offering consultations with herbal health experts or practitioners who can guide individuals on the appropriate use of herbs for specific health concerns or overall well-being and providing information  about the benefits of herbal health.
          </p>
          <div className="text-center mt-5" data-aos="fade-up">
            <Link className="bg-white px-16 rounded-full py-2 text-[#2b982b]" to='/contact-us'>
              Message Us
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <div className="flex flex-col items-center" data-aos="fade-up">
          <h2 className="lg:text-3xl text-2xl font-semibold">
            Our Major Services
          </h2>
          <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="h-auto flex flex-col lg:flex-row justify-around items-center mt-20">
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="flex justify-center">
                <img src={health} alt="" className="h-[100px]" />
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                  The Power of Herbal Health
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Amidst the overwhelming reliance on medications, herbal health
                  stands as a beacon of natural healing.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={chart} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                  Reconnecting Nature for well being
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Herbal remedies echo a symphony of healing from the earth,
                  promoting wellness without harsh side effects.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={energy} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                  Green Remedies for Vitality
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  From ancient healing wisdom to modern natural care, these
                  remedies offer a sustainable path to wellness.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={brain} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                  Embracing Nature's Symphony
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Herbs, the earth's natural remedy, offer a diverse spectrum of
                  solutions promoting holistic health .
                </p>
              </div>
            </div>
          </div>
          <div
            className="h-auto flex flex-col lg:flex-row justify-around items-center lg:mt-20"
            data-aos="fade-up"
          >
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="flex justify-center">
                <img src={doctor} alt="" className="h-[100px]" />
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                Nature's Gift to the Wellness
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                Harness the empowering potential of herbal remedies, a gift from nature fostering holistic wellness.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={heart} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                Applications of Herbal Health
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                Unearth ancient wisdom's relevance in contemporary times through the versatile applications of herbal health.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={running} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                Nature's Essence for Health
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                Experience the nature's essence in herbal remedies, a harmonious blend tailored for restoring health and balance.
                </p>
              </div>
            </div>
            <div
              className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
              data-aos="fade-up"
            >
              <div className="">
                <div className="flex justify-center">
                  <img src={leaf} alt="" className="h-[100px]" />
                </div>
              </div>
              <div className="">
                <h2 className="font-medium text-[17px] mt-4">
                Remedies for Holistic Healing
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                Explore the potent elixirs derived from nature's bounty, designed to holistically heal and revitalize the body.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-16">
          <div className="lg:text-center text-start lg:w-[600px] w-full px-8">
            <h2
              className="text-[green] lg:text-4xl text-3xl font-semibold"
              data-aos="fade-up"
            >
              We Help To Loss Your Weight ?
            </h2>
            <p
              className="text-slate-600 mt-5 lg:text-[16px] text-[15px]"
              data-aos="fade-up"
            >
              Our holistic approach to weight loss integrates the power of natural herbs, offering sustainable solutions to support your journey. We provide tailored guidance, focusing on personalized herbal remedies and nutritional advice to foster a balanced lifestyle.
            </p>
            <div className="mt-4" data-aos="fade-up">
              <Link to="/contact-us">
                Contact Us
                <i className="fa fa-long-arrow-right mx-3 relative top-[2px]"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
