import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";

export default function Navbar() {
  const [toggle, setToggle] = useState(false);

  const toggleSidebar = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
    console.log(toggle);
  };

  return (
    <div className="h-[90px] shadow-sm shadow-[grey] bg-white flex lg:justify-around justify-between items-center">
      <div className="flex items-center justify-center lg:ml-0 ml-3">
        <img src={logo} alt="" className="lg:h-[85px] h-[70px]" />
      </div>
      <div className="lg:flex hidden">
        <div className="flex items-center">
          <Link to="/" className="mx-3 font-medium text-[15px]">
            Home
          </Link>
          <Link to="/services" className="mx-3 font-medium text-[15px]">
            Services
          </Link>
          <Link to="gallery" className="mx-3 font-medium text-[15px]">
            Gallery
          </Link>
          <Link to="/contact-us" className="mx-3 font-medium text-[15px]">
            Contact
          </Link>
        </div>
        <div className="ml-10">
          <a
            className="bg-[#2acb35] px-16 rounded-full py-2 text-white"
            href="tel:8923666030"
          >
            Call Us
          </a>
        </div>
      </div>
      <div className="mr-3 lg:hidden block" onClick={toggleSidebar}>
        <div className=" cursor-pointer">
          {toggle ? (
            <i className="fa fa-close text-xl font-normal"></i>
          ) : (
            <i className="fa fa-bars text-xl"></i>
          )}
        </div>
        <div
          className={`absolute top-[90px] left-0 ${
            toggle ? "h-[250px]" : "h-[0px]"
          } w-full bg-[#2acb35] p-0 transition-all duration-700 z-10 overflow-hidden `}
        >
          <div className="flex flex-col mt-10 ml-5">
            <Link
              to="/"
              className="my-1 text-white hover:text-[green] transition-colors font-medium text-[15px]"
            >
              Home
            </Link>
            <Link
              to="/services"
              className="my-1 text-white hover:text-[green] transition-colors font-medium text-[15px]"
            >
              Services
            </Link>
            <Link
              to="gallery"
              className="my-1 text-white hover:text-[green] transition-colors font-medium text-[15px]"
            >
              Gallery
            </Link>
            <Link
              to="/contact-us"
              className="my-1 text-white hover:text-[green] transition-colors font-medium text-[15px]"
            >
              Contact
            </Link>
            <a
              className="text-white text-[15px] hover:text-[green] my-1"
              href="tel:+917703913197"
            >
              Call Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
