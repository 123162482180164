import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'
import logo from '../Images/logobg.png'

export default function Footer() {

  useEffect(()=>{
    AOS.init({
      offset : 150,
      duration : 800,
    });
  })

  return (
    <div className="bg-[#0a2e4f] mt-16 text-white flex flex-col justify-around md:items-center flex-wrap py-12 px-8">
      <div className="flex flex-col md:flex-row justify-around md:items-center flex-wrap w-full">

      <div className="w-[320px] mt-2 relative -top-2" data-aos="fade-up">
        <Link to="/">
          <div className="flex flex-col">
            <img src={logo} alt="" className="h-[70px] w-[100px]"/>
            <h2 className="text-2xl text-[#2acb35] mt-3 text-start w-full">Natural Herbal Care</h2>
            <p className="mt-2 text-[13px] text-slate-200">Explore our range of natural remedies, supplements, and resources designed to support your journey toward optimal health. let nature nurture your vitality!</p>
          </div>
        </Link>
      </div>

      <div className="text-start mt-8" data-aos="fade-up">
        <ul>
          <div className="mb-2">
            <h1 className="text-lg">Useful Links</h1>
            <div className="h-[2px] w-[75%] bg-[#2acb35] skew-x-12 mt-[1px]"></div>
          </div>
          <li className="text-[13px]">
            <Link to="/">Home</Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="/services">Services</Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="/gallery">Gallery</Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="/contact-us">Contact</Link>
          </li>
          <li className="text-[13px] mt-1">
            <a href="tel:+917703913197">Call Us</a>
          </li>
        </ul>
      </div>

      <div className="text-start mt-8" data-aos="fade-up">
        <ul>
          <div className="mb-2">
            <h1 className="text-lg">Connect With Us</h1>
            <div className="h-[2px] w-[75%] bg-[#2acb35] skew-x-12 mt-[1px]"></div>
          </div>
          <li className="text-[13px] mt-1">
            <Link to="">
              <i className="fa fa-youtube mr-4"></i>
              Youtube
            </Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="">
              <i className="fa fa-facebook mr-4"></i>
              Facebook
            </Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="">
              <i className="fa fa-whatsapp mr-4"></i>
              Whatsapp
            </Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="https://instagram.com/natural_herbal_care?utm_source=qr&igshid=OGIxMTE0OTdkZA==">
              <i className="fa fa-instagram mr-4"></i>
              Instagram
            </Link>
          </li>
          <li className="text-[13px] mt-1">
            <Link to="">
              <i className="fa fa-telegram mr-4"></i>
              Telegram
            </Link>
          </li>
        </ul>
      </div>

      <div className="md:w-[400px] w-[95%] text-start relative md:top-1 mt-8">
        <div className="mb-2">
        <div className="flex flex-col" data-aos="fade-up">
              <h2 className="text-xl font-semibold text-white">
                What we do for you?
              </h2>
              <div className="mt-3">
                <div className="h-[2px] w-[300px] bg-[grey] relative">
                  <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
                </div>
              </div>
            </div>
        </div>
        <p className="text-sm mt-5" data-aos="fade-up">
        We can provide tailored consultations to understand your individual health needs and recommend specific natural herbal remedies or regimes best suited to support your well-being.Suggestions on incorporating herbal health practices into your lifestyle.
        </p>
      </div>

      </div>
    <div className="mt-12">
      <p className="text-sm">© Copyright 2011. Privacy Policy.</p>
    </div>

    </div>
  );
}
