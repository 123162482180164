import React, { useEffect, useState } from "react";
import gainer from "./Images/gainer.png";
import bee from "./Images/bee.png";
import fish from "./Images/fish-bowl.png";
import protien from "./Images/vegetable.png";
import atom from "./Images/atom.png";
import medicine from "./Images/pharmacy.png";
import herbal from "./Images/herbal.png";
import energy from "./Images/renewable-energy.png";
import brain from "./Images/brainstorm.png";
import health from "./Images/healthy.png";
import chart from "./Images/bar-chart.png";
import bgblue from "./Images/bluebg.jpg";
import herbs from "./Images/herbs.jpg";
import herbs2 from "./Images/herbs2.jpeg";
import wl5 from "./Images/wl5.jpg";
import wl9 from "./Images/wl9.jpg";
import wl7 from "./Images/wl7.jpg";
import wl10 from "./Images/wl10.jpg";
import wl11 from "./Images/wl11.jpg";
import wl12 from "./Images/wl12.jpg";
import wl13 from "./Images/wl13.jpg";
import wl14 from "./Images/wl14.jpg";
import wl15 from "./Images/wl15.jpg";
import wl16 from "./Images/wl16.jpg";
import wl17 from "./Images/wl17.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

export default function Homepage() {
  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 800,
    });
    window.scrollTo(0, 0);
  }, []);

  const [faqs, setFaqs] = useState([
    {
      question:
        "Are you facing challenges in managing your weight effectively? ",
      answer:
        "Our natural supplements offer a holistic approach to support your health goals. Expertly blended with a selection of herbs and nutrients, these supplements are tailored to aid in metabolism, boost energy, and assist in maintaining a healthy weight. Coupled with a balanced lifestyle, our supplements can make your weight management journey more attainable and sustainable.",
    },
    {
      question:
        "Struggling with Thyroid Imbalance? How Can Our Herbal Supplement Help?",
      answer:
        "Our specially formulated herbal supplement offers a natural remedy for thyroid health. Enriched with potent herbs known for their thyroid-balancing properties, our supplement aims to support thyroid function. With a carefully curated blend of ingredients, it aids in maintaining the delicate balance of thyroid hormones, promoting overall well-being and vitality.",
    },
    {
      question:
        "High Blood Pressure Concerns? What Does Our Herbal Supplement Offer?",
      answer:
        "Discover the natural path to managing blood pressure with our herbal supplement. Crafted with ingredients known for their blood pressure-regulating benefits, our supplement offers a gentle and effective approach to support cardiovascular health. It aids in maintaining healthy blood pressure levels, promoting a balanced and vibrant life.",
    },
    {
      question:
        "Seeking to Manage Blood Sugar Naturally? Why Consider Our Herbal Supplement?",
      answer:
        "Our herbal supplement is designed to support healthy blood sugar levels. Enriched with potent herbs renowned for their role in glucose regulation, this supplement offers a natural approach to managing blood sugar. It assists in stabilizing sugar levels and promoting overall metabolic health.",
    },
    {
      question:
        "Dealing with PCOD or PCOS? How Can Our Herbal Supplement Support You?",
      answer:
        "Experience relief from PCOD (Polycystic Ovary Syndrome) and PCOS (Polycystic Ovary Disease) with our herbal supplement. Specifically curated with ingredients known for their hormone-balancing properties, this supplement aids in managing the symptoms of PCOD and PCOS. It supports hormonal balance, menstrual health, and overall well-being for a more harmonious life.",
    },
    // Add more FAQ items as needed
  ]);

  return (
    <div className="">
      {/* Headers */}
      <div className="lg:h-[calc(100vh-90px)] h-auto flex lg:flex-row flex-col-reverse justify-around items-center mt-5">
        <div
          className="lg:w-[600px] w-full mt-0 lg:mt-10 lg:p-0 p-6"
          data-aos="fade-right"
        >
          <h1 className="lg:text-[55px] text-4xl md:leading-[70px] leading-[50px] font-bold">
            Nature's <span className="text-[#2acb35]">Journey</span>
            <br /> to Wellness
          </h1>
          <p className=" text-slate-500 text-sm mt-3">
            Unlocking Nature's Healing Power: Herbal Wellness, Your Natural Path
            to Vitality. Discover the potent synergy of herbs, nurturing your
            well-being and harmonizing your health.
          </p>
          <div className="mt-5 flex lg:flex-row flex-col">
            <Link
              className="bg-[#2acb35] px-12 rounded-full py-2 text-white mt-5 lg:mt-0 text-center w-[250px]"
              to="/contact-us"
            >
              Message Us
            </Link>
          </div>
        </div>
        <div className="" data-aos="fade-left">
          <img
            src={gainer}
            alt=""
            className="lg:h-[500px]  lg:w-[340px] h-[250px]"
          />
        </div>
      </div>

      {/* benefits */}
      <div className="lg:h-screen h-auto flex lg:flex-row flex-col justify-around items-center lg:mt-0 mt-20  ">
        <div
          className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
          data-aos="fade-up"
        >
          <div className="flex justify-center">
            <img src={health} alt="" className="h-[100px]" />
          </div>
          <div className="">
            <h2 className="font-medium text-[17px] mt-4">
              The Power of Herbal Health
            </h2>
            <p className="text-[14px] mt-2 text-slate-700">
              Amidst the overwhelming reliance on medications, herbal health
              stands as a beacon of natural healing.
            </p>
          </div>
        </div>
        <div
          className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
          data-aos="fade-up"
        >
          <div className="">
            <div className="flex justify-center">
              <img src={chart} alt="" className="h-[100px]" />
            </div>
          </div>
          <div className="">
            <h2 className="font-medium text-[17px] mt-4">
              Reconnecting Nature for well being
            </h2>
            <p className="text-[14px] mt-2 text-slate-700">
              Herbal remedies echo a symphony of healing from the earth,
              promoting wellness without harsh side effects.
            </p>
          </div>
        </div>
        <div
          className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
          data-aos="fade-up"
        >
          <div className="">
            <div className="flex justify-center">
              <img src={energy} alt="" className="h-[100px]" />
            </div>
          </div>
          <div className="">
            <h2 className="font-medium text-[17px] mt-4">
              Green Remedies for Vitality
            </h2>
            <p className="text-[14px] mt-2 text-slate-700">
              From ancient healing wisdom to modern natural care, these remedies
              offer a sustainable path to wellness.
            </p>
          </div>
        </div>
        <div
          className="w-[280px] text-center shadow-sm shadow-[grey] p-7 rounded-md group cursor-pointer my-2"
          data-aos="fade-up"
        >
          <div className="">
            <div className="flex justify-center">
              <img src={brain} alt="" className="h-[100px]" />
            </div>
          </div>
          <div className="">
            <h2 className="font-medium text-[17px] mt-4">
              Embracing Nature's Symphony
            </h2>
            <p className="text-[14px] mt-2 text-slate-700">
              Herbs, the earth's natural remedy, offer a diverse spectrum of
              solutions promoting holistic health .
            </p>
          </div>
        </div>
      </div>

      {/* Why Use? */}
      <div className="lg:h-[150%] h-auto py-5 lg:mt-0 mt-20">
        <div className="flex flex-col items-center" data-aos="fade-up">
          <h2 className="text-3xl font-semibold px-5">
            Why Use Natural Health
          </h2>
          <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-around items-center my-20">
          <div className="">
            <div
              className="lg:w-[350px] w-full  mt-8 flex lg:flex-row flex-col-reverse items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Lavender - Soothing Fragrance
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Lavender, known for its delightful fragrance, transcends its
                  aromatic qualities as a calming herbal remedy.{" "}
                </p>
              </div>
              <div className="lg:ml-5">
                <img
                  src={herbal}
                  alt=""
                  className="h-[75px] lg:w-[200px] w-[75px] mb-3 lg:mb-0"
                />
              </div>
            </div>
            <div
              className="lg:w-[350px] w-full  mt-8 flex lg:flex-row flex-col-reverse items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Turmeric - Golden Spice
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Hailed as the golden spice, turmeric is not just a culinary
                  delight but also a powerful health enhancer.
                </p>
              </div>
              <div className="lg:ml-5">
                <img
                  src={bee}
                  alt=""
                  className="h-[75px] lg:w-[200px] w-[75px] mb-3 lg:mb-0"
                />
              </div>
            </div>
            <div
              className="lg:w-[350px] w-full  mt-8 flex lg:flex-row flex-col-reverse items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Echinacea - Immune Booster
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Echinacea, revered as a natural immune booster, has been a
                  staple in traditional herbal medicine.
                </p>
              </div>
              <div className="lg:ml-5">
                <img
                  src={atom}
                  alt=""
                  className="h-[75px] lg:w-[200px] w-[75px] mb-3 lg:mb-0"
                />
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <img
              src={herbs}
              alt=""
              className="h-[300px] w-[330px]"
              data-aos="fade-up"
            />
          </div>
          <div className="">
            <div
              className="lg:w-[350px] w-full mt-8 flex lg:flex-row flex-col items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="mr-5">
                <img
                  src={protien}
                  alt=""
                  className="h-[70px] lg:w-[150px] w-[70px]"
                />
              </div>
              <div className="lg:mt-0 mt-3">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Peppermint - Digestive Support
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Peppermint, with its cool, minty flavor, serves as more than a
                  refreshing herb.
                </p>
              </div>
            </div>
            <div
              className="lg:w-[350px] w-full mt-8 flex lg:flex-row flex-col items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="mr-5">
                <img
                  src={medicine}
                  alt=""
                  className="h-[70px] lg:w-[200px] w-[70px]"
                />
              </div>
              <div className="lg:mt-0 mt-3">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Ginger - Versatile Root
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  From soothing an upset stomach to reducing muscle pain and
                  inflammation,It is a versatile herbal remedy.
                </p>
              </div>
            </div>
            <div
              className="lg:w-[350px] w-full mt-8 flex lg:flex-row flex-col items-center px-5 lg:px-0"
              data-aos="fade-up"
            >
              <div className="mr-5">
                <img
                  src={fish}
                  alt=""
                  className="h-[70px] lg:w-[200px] w-[70px]"
                />
              </div>
              <div className="lg:mt-0 mt-3">
                <h2 className="font-medium text-[17px] mt-1 text-[#2acb35]">
                  Garlic - Pungent Bulb
                </h2>
                <p className="text-[14px] mt-2 text-slate-700">
                  Garlic, renowned for its pungent flavor, is a robust ally in
                  supporting overall health.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex lg:flex-row flex-col justify-evenly items-center my-10">
          <div className="">
            <img
              src={herbs2}
              alt=""
              className="h-[250px] w-[250px] rounded-full"
              data-aos="fade-up"
            />
          </div>
          <div className="md:w-[600px] w-[95%] px-8">
            <ul className=" list-disc mt-8">
              <li className="my-2" data-aos="fade-up">
                Obesity is a complex health condition that goes beyond mere
                weight gain; it significantly impacts one's overall well-being.
                It's not just a matter of appearance but a concern that affects
                various aspects of physical and mental health.
              </li>
              <li className="my-2" data-aos="fade-up">
                Our supplements are designed to promote a healthy metabolism.
                With a blend of natural ingredients known for their
                metabolic-boosting properties, they can assist in enhancing your
                body's ability to burn calories efficiently.
              </li>
              <li className="my-2" data-aos="fade-up">
                Unlike some conventional weight management solutions, our
                natural supplements aim to support your health without the
                potential for harsh side effects commonly associated with
                certain synthetic weight-loss products.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* supplements */}
      <div
        className="h-[500px] w-full mt-10 relative"
        style={{ backgroundImage: `url(${bgblue})`, backgroundSize: "cover" }}
      >
        <div className="bg-[#0a2e4f] h-full w-full flex justify-center items-center opacity-[0.9]">
          <div className="lg:w-[600px] w-full p-5">
            <h3
              className="text-white lg:text-[38px] text-3xl leading-[50px] font-semibold text-center"
              data-aos="fade-up"
            >
              Awesome Supplement For Your Body We Have 15 Years Of Experience
            </h3>
            <p
              className="text-white text-sm text-center mt-5 lg:px-4 px-0"
              data-aos="fade-up"
            >
              Backed by our extensive knowledge and experience in herbal health,
              these supplements are a testament to our unwavering dedication to
              quality, ensuring you receive the best for your body's nourishment
              and overall health.
            </p>
            <div className="text-center mt-8" data-aos="fade-up">
              <Link
                className="bg-[#2acb35] px-16 rounded-full py-2 text-white"
                to="/contact-us"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Blogs */}
      <div className="py-10">
        <div className="flex flex-col items-center" data-aos="fade-up">
          <h2 className="lg:text-3xl text-2xl font-semibold px-5">
            Some Major Transformations
          </h2>
          <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div>
        </div>
        <div className="mt-16 flex flex-col justify-around items-center">
          <div className="flex lg:flex-row flex-col flex-wrap items-center justify-evenly w-full mt-8">
            <img
              src={wl5}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />

            <img
              src={wl9}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl7}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl10}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl11}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl12}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl13}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl14}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl15}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl16}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
            <img
              src={wl17}
              alt=""
              className="lg:h-[400px] lg:w-[400px] h-[340px] w-[340px] my-2"
              data-aos="fade-up"
            />
          </div>
        </div>
      </div>

      {/* Founders */}
      <div
        className="lg:h-[600px] h-auto w-full mt-10 relative border-2 border-[#062a4d]"
        style={{ backgroundImage: `url(${bgblue})`, backgroundSize: "cover" }}
      >
        <div className="bg-[#0a2e4f] h-full opacity-[0.9] flex justify-center items-center">
          <div className="w-full lg:py-0 py-10">
            <div
              className="flex flex-col items-center lg:mb-20 my-10"
              data-aos="fade-up"
            >
              <h2 className="lg:text-3xl text-2xl font-semibold text-white">
                Our Vision for Health
              </h2>
              <div className="mt-3">
                <div className="h-[2px] w-[300px] bg-[grey] relative">
                  <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-around items-center w-full lg:p-0 p-4">
              <div className="lg:w-[450px] w-full mt-3" data-aos="fade-up">
                <div className="text-center">
                  <i className="fa fa-user text-[80px] text-white"></i>
                </div>
                <p className="text-white text-sm text-center mt-5">
                  The founder's vision and leadership have laid the foundation
                  for this exceptional product. Their years of experience,
                  expertise, and dedication are evident in the product's quality
                  and innovation.
                </p>
                <div className="mt-6 flex flex-col items-center">
                  <div className="bg-[#2acb35] h-1 w-[100px] skew-x-[40deg] "></div>
                </div>
              </div>
              <div
                className="lg:w-[450px] w-full lg:mt-3 mt-10"
                data-aos="fade-up"
              >
                <div className="text-center">
                  <i className="fa fa-user text-[80px] text-white"></i>
                </div>
                <p className="text-white text-sm text-center mt-5">
                  The co-founder's unique and helpful contributions have
                  seamlessly complemented the founder's vision, adding another
                  layer of expertise and skill that enhances the product's
                  overall appeal.
                </p>
                <div className="mt-6 flex flex-col items-center">
                  <div className="bg-[#2acb35] h-1 w-[100px] skew-x-[40deg] "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div className="max-w-3xl mx-auto py-4 my-20 px-10">
        <div className="flex flex-col items-center mb-16" data-aos="fade-up">
          <h2 className="lg:text-3xl text-2xl font-semibold px-5">
            Frequently Asked Questions
          </h2>
          <div className="mt-3">
            <div className="h-[2px] w-[300px] bg-[grey] relative">
              <div className="h-2 w-2 rounded-full bg-[#37a53f] absolute left-0 -top-[3px] head-animation"></div>
            </div>
          </div>
        </div>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4 my-10" data-aos="fade-up">
            <div className="flex items-center justify-between border-b border-gray-200 cursor-pointer">
              <h2 className="text-md font-medium mb-2">{faq.question}</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 transform transition-transform duration-300"
                viewBox="0 0 20 20"
                fill="currentColor"
                onClick={() =>
                  setFaqs((prevFaqs) =>
                    prevFaqs.map((item, i) =>
                      i === index ? { ...item, open: !item.open } : item
                    )
                  )
                }
              >
                <path
                  fillRule="evenodd"
                  d="M10 19a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v15a1 1 0 0 1-1 1zm-7-8a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1zm14 0a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v7a1 1 0 0 1-1 1z"
                />
              </svg>
            </div>
            {faq.open && (
              <p className="text-gray-600 text-sm mt-2">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>

      {/* Scores */}
      <div className="flex flex-col md:flex-row justify-around my-20">
        <div className="text-center my-4" data-aos="fade-up">
          <div className="">
            <i className="fa fa-youtube text-5xl text-[#0a2e4f] font-medium"></i>
          </div>
          <div className="mt-5">
            <h4 className="text-5xl text-[#2acb35] font-semibold">5K +</h4>
            <p className="mt-1">Followers</p>
          </div>
        </div>
        <div className="text-center my-4" data-aos="fade-up">
          <div className="">
            <i className="fa fa-instagram text-5xl text-[#0a2e4f] font-medium"></i>
          </div>
          <div className="mt-5">
            <h4 className="text-5xl text-[#2acb35] font-semibold">15K +</h4>
            <p className="mt-1">Followers</p>
          </div>
        </div>
        <div className="text-center my-4" data-aos="fade-up">
          <div className="">
            <i className="fa fa-telegram text-5xl text-[#0a2e4f] font-medium"></i>
          </div>
          <div className="mt-5">
            <h4 className="text-5xl text-[#2acb35] font-semibold">5K +</h4>
            <p className="mt-1">Followers</p>
          </div>
        </div>
        <div className="text-center my-4" data-aos="fade-up">
          <div className="">
            <i className="fa fa-facebook text-5xl text-[#0a2e4f] font-medium"></i>
          </div>
          <div className="mt-5">
            <h4 className="text-5xl text-[#2acb35] font-semibold">10K +</h4>
            <p className="mt-1">Followers</p>
          </div>
        </div>
      </div>

      <div className="text-center" data-aos="fade-up">
        <p className="text-xs">Fell Free To Contact Us On :</p>
        <p className="font-medium">naturalherbalcare369@gmail.com</p>
      </div>
    </div>
  );
}
