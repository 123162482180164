import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function ContactUs() {

  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [contact,setContact] = useState('');
  const [address,setAddress] = useState('');
  const [query,setQuery] = useState('');
  const [send,setSend] = useState(false);

  useEffect(() => {
    AOS.init({
      offset: 150,
      duration: 800,
    });
    window.scrollTo(0,0);
  },[]);

  const sendQuery = async(e)=>{
    e.preventDefault();
    setSend(true);
    toast("Sending Your Message...");
    try {
      let resp = await fetch('https://naturalherbalcare.onrender.com/user-query',{
        method : 'post',
        body : JSON.stringify({name,contact,email,address,query}),
        headers : {
          'Content-type' : 'application/json'
        }
      })
      resp = await resp.json();
      if(resp){
        toast("Message Sent Successfully");
        setName('');
        setContact('');
        setEmail('');
        setAddress('');
        setQuery('');
        
      }
    } catch (error) {
      toast("Failed To Send.");
      console.log(error)
    }
    setSend(false);

  }

  return (
    <div className="lg:h-[100vh] h-auto w-full flex lg:flex-row flex-col justify-center items-center">
      <ToastContainer />
      <div className="lg:h-[95%] lg:w-[90%] h-auto w-full rounded-md shadow-sm shadow-[grey] lg:p-16 p-4 flex lg:flex-row flex-col justify-around items-center mt-10">
        <div
          className="lg:w-[550px] w-[98%] flex justify-center items-center h-full p-2"
          data-aos="fade-right"
        >
          <div className="">
            <i className="fa fa-leaf text-[#2acb35] text-[55px]"></i>
            <h2 className="text-[#0a2e4f] font-bold text-2xl mt-2">
              Hello User,
            </h2>
            <p className="text-slate-600 text-[13px] mt-2">
            Your journey to wellness starts here. Reach out to us and discover the transformative power of natural herbal care. Contact us today and take the first step towards a healthier, more vibrant you.
            </p>
            <div className="flex items-center mt-5">
              <i className="fa fa-phone"></i>
              <p className="ml-3 text-sm">+91 8273484306</p>
            </div>
            <div className="flex items-center mt-3">
              <i className="fa fa-envelope text-sm"></i>
              <p className="ml-3 text-sm">naturalherbalcare369@gmail.com</p>
            </div>
            <div className="flex items-center mt-3">
              <i className="fa fa-map-marker text-sm"></i>
              <p className="ml-4 text-sm">
                Haridwar, India
              </p>
            </div>
          </div>
        </div>

        <div
          className="flex flex-col justify-center items-end h-full w-full mt-10 lg:mt-0 p-2"
          data-aos="fade-left"
        >
          <div className="w-full">
            <h2 className="text-[#0a2e4f] font-bold text-2xl mt-1 w-full text-center">
              Get In Touch
            </h2>
            <div className="w-full flex justify-center">
              <form action="w-full" onSubmit={sendQuery}>
                <div className="my-3">
                  <input
                    type="text"
                    className="h-[36px] lg:w-[350px] w-full border border-[grey] rounded-full pl-4 text-sm outline-none"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    required
                  />
                </div>
                <div className="my-3">
                  <input
                    type="email"
                    className="h-[36px] lg:w-[350px] w-full border border-[grey] rounded-full pl-4 text-sm outline-none"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                    required
                  />
                </div>
                <div className="my-3">
                  <input
                    type="tel"
                    className="h-[36px] lg:w-[350px] w-full border border-[grey] rounded-full pl-4 text-sm outline-none"
                    placeholder="Enter Contact"
                    value={contact}
                    onChange={(e)=>{setContact(e.target.value)}}
                    required
                  />
                </div>
                <div className="my-3">
                  <input
                    type="text"
                    className="h-[36px] lg:w-[350px] w-full border border-[grey] rounded-full pl-4 text-sm outline-none"
                    placeholder="Enter Address"
                    value={address}
                    onChange={(e)=>{setAddress(e.target.value)}}
                    required
                  />
                </div>
                <div className="my-3">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="border border-[grey] rounded-lg resize-none lg:w-[350px] w-full outline-none p-3 text-sm"
                    placeholder="Enter Your Query"
                    value={query}
                    onChange={(e)=>{setQuery(e.target.value)}}
                    required
                  ></textarea>
                </div>
                <div className="text-center">
                  <button className="bg-[#2acb35] px-12 rounded-full py-2 text-white" type="submit">
                    {
                      send?<span>Sending Message...</span>:<span>Send Message</span>
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
